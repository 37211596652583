import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes, FaHome, FaTools, FaBook, FaDollarSign, FaStar, FaEnvelope, FaInfoCircle, FaBlog, FaCaretDown } from 'react-icons/fa';
import styles from './Navbar.module.css';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const mainMenuItems = [
    { to: "/", label: "Home", icon: <FaHome /> },
    { to: "/tools", label: "Tools", icon: <FaTools /> },
    { to: "/tutorials", label: "Tutorials", icon: <FaBook /> },
    { to: "/pricing", label: "Pricing", icon: <FaDollarSign /> },
    { to: "/reviews", label: "Reviews", icon: <FaStar /> },
    { to: "/contact", label: "Contact", icon: <FaEnvelope /> },
  ];

  const moreMenuItems = [
    // { to: "/speedtest", label: "Speed Test", icon: <FaTachometerAlt /> },
    { to: "/about", label: "About", icon: <FaInfoCircle /> },
    // { to: "/privacy-policy", label: "Privacy Policy", icon: <FaInfoCircle /> },
    { to: "/blog/list", label: "Blog", icon: <FaBlog /> }
  ];

  const menuVariants = {
    closed: {
      opacity: 0,
      y: "-100%",
      transition: { 
        duration: 0.2,
        ease: "easeInOut"
      }
    },
    open: {
      opacity: 1,
      y: "0%",
      transition: { 
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    setIsOpen(false); // Close mobile menu if open
  };

  return (
    <nav className={`${styles.navbar} ${scrolled ? styles.scrolled : ''}`}>
      <div className={styles.navbarContent}>
        <Link to="/" className={styles.logo} onClick={handleLinkClick}>
          <span className={styles.logoText}>SPEED</span>
          <span className={styles.logoAccent}>BUY</span>
        </Link>

        <motion.button
          className={styles.menuButton}
          onClick={() => setIsOpen(!isOpen)}
          whileTap={{ scale: 0.95 }}
          aria-label="Toggle menu"
        >
          {isOpen ? <FaTimes /> : <FaBars />}
        </motion.button>

        <div className={styles.desktopMenu}>
          <ul className={styles.menu}>
            {mainMenuItems.map((item) => (
              <li key={item.to} className={location.pathname === item.to ? styles.active : ''}>
                <Link to={item.to} onClick={handleLinkClick}>
                  {item.icon}
                  <span>{item.label}</span>
                </Link>
              </li>
            ))}
            <li 
              className={`${styles.moreMenuItem} ${showMore ? styles.active : ''}`}
              onMouseEnter={() => setShowMore(true)}
              onMouseLeave={() => {
                // Add delay before closing to allow movement to dropdown
                setTimeout(() => setShowMore(false), 100);
              }}
            >
              <span className={styles.moreMenuTrigger}>
                More
                <FaCaretDown />
              </span>
              <ul className={styles.moreMenuDropdown}
                onMouseEnter={() => setShowMore(true)}
                onMouseLeave={() => setShowMore(false)}
              >
                {moreMenuItems.map((item) => (
                  <li key={item.to}>
                    <Link to={item.to} onClick={handleLinkClick}>
                      {item.icon}
                      <span>{item.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
          <div className={styles.buttons}>
            <Link to="/login" className={styles.loginBtn} onClick={handleLinkClick}>Login</Link>
            <Link to="/signup" className={styles.signupBtn} onClick={handleLinkClick}>Signup</Link>
          </div>
        </div>

        <AnimatePresence>
          {isOpen && (
            <>
              <motion.div
                className={styles.mobileMenu}
                initial="closed"
                animate="open"
                exit="closed"
                variants={menuVariants}
              >
                <ul className={styles.mobileNav}>
                  {[...mainMenuItems, ...moreMenuItems].map((item) => (
                    <motion.li 
                      key={item.to}
                      whileTap={{ scale: 0.95 }}
                      className={location.pathname === item.to ? styles.active : ''}
                    >
                      <>
                        <Link to={item.to} onClick={handleLinkClick}>
                          {item.icon}
                          <span>{item.label}</span>
                        </Link>
                      </>
                    </motion.li>
                  ))}
                </ul>
                <div className={styles.mobileButtons}>
                  <Link to="/login" className={styles.loginBtn} onClick={handleLinkClick}>
                    Login
                  </Link>
                  <Link to="/signup" className={styles.signupBtn} onClick={handleLinkClick}>
                    Signup
                  </Link>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar;
